<template>
    <v-navigation-drawer
        v-if="status === 'authenticated' && projects?.length"
        class="app-menu">
        <v-list
            density="compact"
            open-strategy="single">
            <v-list-group>
                <template #activator="slotProps">
                    <v-list-item
                        v-bind="slotProps.props"
                        prepend-icon="$home"
                        density="compact"
                        @click="navigateTo({ name: 'projects-list' })">
                        {{ $t('titles.projectList') }}
                    </v-list-item>
                </template>
                <project-menu
                    v-for="project in menuProjects"
                    :key="`project-${project.id}`"
                    :project="project" />
            </v-list-group>
            <v-list-item
                prepend-icon="$plus"
                :to="{ name: 'projects-wizard' }"
                >{{ $t('buttons.createProject') }}</v-list-item
            >
        </v-list>
        <template #append>
            <slot name="append" />
        </template>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
import type { Pausable } from '@vueuse/core';

const projectsStore = useProjectsStore();
const { projects, showDeletedProjects } = storeToRefs(projectsStore);
const { status } = useAuth();

const menuProjects = computed(() => {
    if (status.value === 'unauthenticated') {
        return [];
    }

    const sortedStatus = ['running', 'updating', 'deleting', 'degraded'];

    if (showDeletedProjects.value) {
        sortedStatus.push('deleted');
    }

    let res: TProject[] = [];

    sortedStatus.forEach((status) => {
        const projs = (projects.value || [])
            .filter((p) => p.status === status)
            .sort((a: TProject, b: TProject) => a.name.localeCompare(b.name));
        res = res.concat(projs);
    });
    return res;
});
const intervalFns: Record<string, Pausable> = {};

watchEffect(() => {
    if (projects.value) {
        projects.value
            .filter((p) => ['running', 'degraded'].includes(p.status || ''))
            .forEach((p) => {
                const topoStore = useProjectTopologyStore(p);

                if (!intervalFns[p.id]) {
                    const interval = useDynamicProjectRefreshRate(p);

                    intervalFns[p.id] = useIntervalFn(
                        topoStore.refresh,
                        interval,
                        {
                            immediate: status.value === 'authenticated',
                            immediateCallback: true,
                        }
                    );
                } else if (
                    !intervalFns[p.id].isActive.value &&
                    status.value === 'authenticated'
                ) {
                    intervalFns[p.id].resume();
                }
            });
    }

    projects.value.forEach((p: TProject) => {
        if (
            intervalFns[p.id]?.isActive.value &&
            !['running', 'degraded'].includes(p.status || '')
        ) {
            intervalFns[p.id].pause();
        }
    });
});
watchEffect(() => {
    if (status.value === 'unauthenticated') {
        Object.values(intervalFns).forEach((i) => i.pause());
    }
});
</script>
<style lang="scss">
.v-theme--light {
    .v-avatar.default-avatar {
        filter: invert(55%);
    }
}

.v-navigation-drawer {
    .v-list {
        padding-top: 0;

        .v-list-item {
            font-size: 14px;
        }
    }
}
</style>
