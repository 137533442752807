<template>
    <confirm-dialog v-bind="$attrs">
        <template #title>
            {{ $t('titles.confirm_deletion') }}
        </template>

        <template #confirmBtnTxt>
            {{ $t('buttons.delete') }}
        </template>

        <slot />
    </confirm-dialog>
</template>
