<template>
    <template v-if="sortedDashboards?.length">
        <template v-if="sortedDashboards.length === 1">
            <v-list-item
                prepend-icon="$data"
                density="compact"
                :to="{
                    name: 'projects-projectId-dashboards-dashboardId',
                    params: {
                        projectId: project.id,
                        dashboardId: sortedDashboards[0].id,
                    },
                }">
                {{ $t('titles.data') }}
            </v-list-item>
        </template>
        <v-list-group
            v-else
            subgroup>
            <template #activator="slotProps">
                <v-list-item
                    density="compact"
                    v-bind="slotProps.props">
                    {{ $t('titles.data') }}
                </v-list-item>
            </template>
            <v-hover
                v-for="dashboard in sortedDashboards"
                :key="dashboard.id">
                <template #default="slotProps">
                    <v-list-item
                        density="compact"
                        :to="{
                            name: 'projects-projectId-dashboards-dashboardId',
                            params: {
                                projectId: project.id,
                                dashboardId: dashboard.id,
                            },
                        }"
                        nav
                        prepend-icon="mdi-view-dashboard"
                        v-bind="slotProps.props">
                        {{ dashboard.label }}
                        <template
                            v-if="dashboard.id !== 'default'"
                            #append>
                            <v-fade-transition>
                                <crud-actions
                                    v-if="slotProps.isHovering"
                                    enable-delete
                                    @delete="
                                        async () => await remove(dashboard)
                                    " />
                            </v-fade-transition>
                        </template>
                    </v-list-item>
                </template>
            </v-hover>
        </v-list-group>
    </template>
</template>
<script setup lang="ts">
type TProps = {
    project: TProject;
};
const props = defineProps<TProps>();

const dashboardStore =
    props.project.status === 'running'
        ? useProjectDashboardsStore(props.project.id)
        : undefined;
const { dashboards } = dashboardStore
    ? storeToRefs(dashboardStore)
    : { dashboards: undefined };
const sortedDashboards = computed(() => {
    return (
        dashboards?.value?.sort((a, b) => a.label.localeCompare(b.label)) || []
    );
});
const $route = useRoute();
async function remove(dashboard: ZettascaleDashboard) {
    if (dashboard.id === 'default') {
        return;
    }

    dashboardStore?.removeDashboard(dashboard);

    if ($route.params.dashboardId === dashboard.id) {
        await navigateTo({
            name: 'projects-projectId-dashboards',
            params: {
                projectId: props.project.id,
            },
        });
    }
}
</script>
