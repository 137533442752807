import { OrgKind } from '@ZettaScaleLabs/continuum-node-client/dist/organization';

export default (p: TProject) => {
    const route = useRoute();
    const runtimeCfg = useRuntimeConfig();
    const organisationsStore = useOrganisationsStore();
    const { organisations } = storeToRefs(organisationsStore);

    const isPremiumUser = computed(() =>
        [OrgKind.CORPORATE, OrgKind.CC].includes(
            organisations.value.at(0)?.kind ?? OrgKind.UNRECOGNIZED
        )
    );

    return computed(() => {
        if (route.params.projectId === p.id && isPremiumUser.value) {
            return runtimeCfg.public.topology_refresh_interval_premium;
        }
        return runtimeCfg.public.topology_refresh_interval;
    });
};
