<template>
    <v-list-group>
        <template #activator="slotProps">
            <v-list-item
                v-bind="slotProps.props"
                density="compact">
                <template #title>
                    <object-status
                        dot
                        inline
                        :status="project.status"
                        mode="badge">
                        <div class="text-body-2 pr-2">
                            {{ project.name }}
                        </div>
                    </object-status>
                </template>
            </v-list-item>
        </template>
        <template v-if="project.status === 'running'">
            <v-list-item
                :to="{
                    name: 'projects-projectId',
                    params: { projectId: project.id },
                }"
                exact
                density="compact"
                prepend-icon="$home">
                {{ $t('titles.home') }}
            </v-list-item>
            <v-fade-transition>
                <v-list-item
                    v-if="hasDDS"
                    :to="{
                        name: 'projects-projectId-dds',
                        params: {
                            projectId: project.id,
                        },
                    }">
                    <template #prepend>
                        <dds-badge
                            class="mr-7"
                            height="24"
                            width="24" />
                    </template>
                    {{ $t('dds.titles.main') }}
                </v-list-item>
            </v-fade-transition>
            <v-list-group
                v-for="provider in providers"
                :key="`project-${project.id}-provider-${provider.id}-menu`"
                subgroup>
                <template #activator="slotProps">
                    <v-list-item
                        v-bind="slotProps.props"
                        density="compact">
                        {{ provider.name }}
                    </v-list-item>
                </template>

                <template v-if="provider.id !== ONPREM_PROVIDER_ID">
                    <v-list-group
                        v-for="region in usedProviderRegions(provider)"
                        :key="`project-${project.id}-provider-${provider.id}-region-${region.id}-menu`"
                        subgroup>
                        <template #activator="slotProps">
                            <v-list-item
                                v-bind="slotProps.props"
                                density="compact">
                                {{ region.name }}
                            </v-list-item>
                        </template>

                        <router-menu
                            v-for="router in routers.filter(
                                (r) => r.raw.metadata?.region_id === region.id
                            )"
                            :key="router.raw.zid"
                            :router="router"
                            :project="project" />
                    </v-list-group>
                </template>
                <template v-else>
                    <router-menu
                        v-for="router in routers.filter(
                            (r) =>
                                r.raw.metadata?.provider_id ===
                                ONPREM_PROVIDER_ID
                        )"
                        :key="router.raw.zid"
                        :router="router"
                        :project="project" />
                </template>
            </v-list-group>

            <v-list-item
                :to="{
                    name: 'projects-projectId-map',
                    params: { projectId: project.id },
                }"
                exact
                prepend-icon="$topology"
                density="compact">
                {{ $t('titles.networkMap') }}
            </v-list-item>

            <project-dashboards-menu :project="project" />

            <v-list-item
                prepend-icon="$chart"
                :to="{
                    name: 'projects-projectId-metrics',
                    params: { projectId: project.id },
                }"
                density="compact">
                {{ $t('titles.projectMetrics') }}
            </v-list-item>

            <v-list-item
                prepend-icon="$add"
                :to="{
                    name: 'projects-projectId-clients',
                    params: { projectId: project.id },
                }"
                density="compact">
                {{ $t('titles.usePaas') }}
            </v-list-item>

            <v-list-group subgroup>
                <template #activator="slotProps">
                    <v-list-item
                        v-bind="slotProps.props"
                        density="compact"
                        >{{ $t('administration') }}</v-list-item
                    >
                </template>

                <v-list-item
                    prepend-icon="$edit"
                    :to="{
                        name: 'projects-projectId-edit',
                        params: { projectId: project.id },
                    }"
                    density="compact"
                    >Edit</v-list-item
                >
                <v-list-item
                    class="cursor-pointer"
                    prepend-icon="$delete"
                    :active="confirmDeletion"
                    density="compact"
                    @click="confirmDeletion = true"
                    >{{ $t('buttons.delete') }}</v-list-item
                >
                <confirm-deletion-dialog
                    v-model="confirmDeletion"
                    @confirm="() => deleteProject(project)">
                    {{ $t('confirm_project_deletion') }}
                </confirm-deletion-dialog>
            </v-list-group>
        </template>
        <template v-else>
            <v-list-item
                prepend-icon="$chart"
                density="compact"
                :to="{
                    name: 'projects-projectId-metrics',
                    params: { projectId: project.id },
                }">
                {{ $t('titles.projectMetrics') }}
            </v-list-item>
        </template>
    </v-list-group>
</template>

<script setup lang="ts">
type TProps = {
    project: TProject;
};
const props = defineProps<TProps>();

const deleteProject = useDeleteProject();
const confirmDeletion = ref(false);
const topologyStore = useProjectTopologyStore(props.project);
const { topology } = storeToRefs(topologyStore);
const routers = useProjectRouters(props.project);

const providerStore = useProvidersStore();
const { providers: providers$ } = storeToRefs(providerStore);

const providers = computed(() => {
    // const ids = routers.value
    //     .map((r) => r.raw.metadata?.region_id)
    //     .filter((x) => !!x);

    const providerIds = new Set([
        ...routers.value
            .map((r) => r.raw.metadata?.provider_id)
            .filter((x) => !!x),
        // ...providers$.value.filter(p => {
        //     const rs = p.regions.map(r => r.id);
        //     return rs.find(r => ids.includes(r));
        // })
    ]);

    return providers$.value.filter((p) => providerIds.has(p.id));
});

const usedProviderRegions = function (provider: TProvider) {
    const ids = routers.value
        .map((r) => r.raw.metadata?.region_id)
        .filter((x) => !!x);

    return provider.regions.filter((r) => ids.includes(r.id));
};

const hasDDS = computed(
    () => topology.value.dds && Object.keys(topology.value.dds).length > 0
);
</script>
