<template>
    <v-layout fluid>
        <app-header class="border-b" />

        <v-main class="bg-background-secondary">
            <v-layout
                fluid
                class="h-100">
                <app-menu
                    permanent
                    width="350"
                    :rail="!pinMenu"
                    color="background"
                    expand-on-hover>
                    <template #append>
                        <div class="tips font-italic pa-2">
                            <template v-if="!pinMenu">
                                {{
                                    $t('pinMenu', { key: appCfg.ux.pinMenuKey })
                                }}
                            </template>
                            <template v-else>
                                {{
                                    $t('unpinMenu', {
                                        key: appCfg.ux.pinMenuKey,
                                    })
                                }}
                            </template>
                        </div>
                    </template>
                </app-menu>

                <v-main class="pb-10 mx-4 mb-2">
                    <div class="d-flex justify-space-between flex-wrap my-2">
                        <app-breadcrumb />

                        <v-spacer />
                        <div class="d-flex align-center gap-2">
                            <div
                                id="page-actions-prepend"
                                class="d-flex align-center gap-2"></div>
                            <div
                                id="page-actions"
                                class="d-flex align-center gap-2"></div>
                            <div
                                id="page-actions-append"
                                class="d-flex align-center gap-2"></div>
                        </div>
                    </div>

                    <v-layout
                        fluid
                        class="h-100 pb-10">
                        <v-main
                            scrollable
                            class="mb-10">
                            <slot />
                        </v-main>
                    </v-layout>
                </v-main>
            </v-layout>
        </v-main>
        <app-footer />
    </v-layout>
</template>
<script setup lang="ts">
const keys = useMagicKeys();
const appCfg = useAppConfig();
const pinMenuTrigger = keys[appCfg.ux.pinMenuKey];
const settingsStore = useSettingsStore();
const { pinMenu } = storeToRefs(settingsStore);

whenever(pinMenuTrigger, () => {
    pinMenu.value = !pinMenu.value;
});
</script>
