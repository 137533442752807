<template>
    <v-list-group subgroup>
        <template #activator="slotProps">
            <v-list-item v-bind="slotProps.props">
                {{ router.raw.metadata?.name || router.raw.zid }}
            </v-list-item>
        </template>

        <v-list-item
            :to="{
                name: 'projects-projectId-routers-routerId-info',
                params: {
                    projectId,
                    routerId,
                },
            }"
            prepend-icon="$info-outlined">
            {{ $t('titles.infos') }}
        </v-list-item>

        <!-- <v-list-item
            :to="{
                name: 'projects-projectId-routers-routerId-sessions',
                params: {
                    projectId,
                    routerId,
                },
            }"
            prepend-icon="$lan">
            {{ $t('titles.raw.sessions') }}
        </v-list-item>
        -->

        <v-list-item
            v-if="
                router.raw.metadata?.region_id !== ONPREM_PROVIDER_ID &&
                regions.find((r) => router.raw.metadata?.region_id === r)
            "
            :to="{
                name: 'projects-projectId-routers-routerId-metrics',
                params: {
                    projectId,
                    routerId,
                },
            }"
            prepend-icon="$chart">
            {{ $t('titles.routerMetrics') }}
        </v-list-item>
    </v-list-group>
</template>
<script setup lang="ts">
type TProps = {
    router: NetworkRouter;
    project: TProject;
};
const props = defineProps<TProps>();
const routerId = computed(() => props.router.raw.zid.replace(/-/gi, ''));
const providersStore = useProvidersStore();
const { providers } = storeToRefs(providersStore);
const regions = computed(
    () => providers.value?.flatMap((p) => p.regions.map((r) => r.id)) || []
);

const projectId = computed(() => props.project.id);
</script>
