<template>
    <ul
        v-if="count > 0"
        class="v-breadcrumbs v-breadcrumbs--density-default pb-0 px-0 text-small d-flex pt-0 text-subtitle-2 text-grey-darken-2 flex-wrap">
        <template
            v-for="(crumb, i) in elements"
            :key="crumb.href">
            <app-breadcrumb-item
                :crumb="crumb"
                :force-pseudo="i === elements.length - 1" />
            <li
                v-if="i + 1 < count"
                class="v-breadcrumbs-divider text-subtitle-2 text-grey-darken-2">
                /
            </li>
        </template>
    </ul>
</template>
<script setup lang="ts">
const { elements } = useBreadcrumb();

const count = computed(() => elements.value.length || 0);
</script>
<style lang="scss" scoped>
@import 'vuetify/lib/components/VBreadcrumbs/VBreadcrumbs.css';
@import 'vuetify/lib/styles/settings/_variables.scss';
.v-breadcrumbs-divider {
    padding: $spacer * 0.5;
}
.v-breadcrumbs-item {
    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        color: rgb(var(--v-theme-primary));
    }
}
</style>
